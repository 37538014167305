<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>LIST PENGIRIMAN</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col>
        <v-card outlined>
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-4">
              <v-btn color="success" rounded to="/penjualan/pengiriman/input"
                ><v-icon>mdi-plus-thick</v-icon>Tambah Data</v-btn
              >
            </div>
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Awal"
                    :value="formatDate(periode.tanggal1)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal1"
                  @change="loadData()"
                  @input="periode.pickerTanggal1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Akhir"
                    :value="formatDate(periode.tanggal2)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal2"
                  @change="loadData()"
                  @input="periode.pickerTanggal2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div>
              <v-text-field
                name="name"
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
              ></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <!-- <div>
              <download-excel
                class="btn btn-default"
                worksheet="Data Pengiriman"
                name="Data Pengiriman.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
            <div>
              <v-btn color="primary" text @click="loadData()"
                >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-card outlined class="mt-2">
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
              >
                <template v-slot:[`item.tanggal`]="{ item }">
                  {{ formatDate(item.tanggal) }}
                </template>
                <template v-slot:[`item.jumlah`]="{ item }">
                  {{ formatNumber(item.jumlah) }}
                </template>

                <template v-slot:[`item.no_sj`]="{ item }">
                  <div class="d-flex justify-space-around align-center">
                    {{ item.no_sj }}
                    <v-spacer></v-spacer>
                  </div>
                </template>

                <template v-slot:[`item.opsi`]="{ item }">
                  <div class="d-flex justify-center">
                    <v-btn
                      color="primary"
                      small
                      class="mr-2"
                      :outlined="item.sta_print_sj != '1'"
                      rounded
                      @click="printSj(item.no_sj)"
                    >
                      <v-icon small class="mr-2" v-if="item.sta_print_sj != '1'"
                        >mdi-printer</v-icon
                      >
                      <v-icon small class="mr-2" v-else
                        >mdi-printer-check</v-icon
                      >
                      SJ</v-btn
                    >
                    <v-btn
                      color="indigo"
                      dark
                      small
                      rounded
                      class="mr-2"
                      :outlined="item.sta_print_nota != '1'"
                      @click="printInvoiceDev(item.no_bukti)"
                      ><v-icon
                        small
                        class="mr-2"
                        v-if="item.sta_print_nota != '1'"
                        >mdi-printer</v-icon
                      >
                      <v-icon small class="mr-2" v-else
                        >mdi-printer-check</v-icon
                      >Nota</v-btn
                    >
                  </div>
                </template>

                <!-- VALIDASI PENGIRIMAN -->
                <template v-slot:[`item.validasi`]="{ item }">
                  <div v-if="item.sta_validasi != '1'">
                    <v-btn
                      v-if="item.sta_batal != '1'"
                      color="success"
                      outlined
                      small
                      rounded
                      class="mr-2"
                      @click="
                        showValidasi(item.no_sj, item.no_bukti, item.nama)
                      "
                      ><v-icon small class="mr-2"
                        >mdi-check-circle-outline</v-icon
                      >
                      VALIDASI</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex align-center"
                        >
                          <v-icon color="success" small
                            >mdi-check-circle</v-icon
                          >
                          {{ formatDate(item.tgl_validasi) }}
                        </div>
                      </template>
                      <!-- Tooltip content -->
                      <span>
                        <div>Ket : {{ item.ket_validasi }}</div>
                        <div>User : {{ item.user_validasi }}</div>
                        <div>
                          Waktu : {{ formatDateTime(item.tgl_validasi) }}
                        </div>
                      </span>
                    </v-tooltip>
                  </div>
                </template>
                <!-- END VALIDASI PENGIRIMAN -->

                <!-- BATAL PENGIRIMAN -->
                <template v-slot:[`item.opsi_batal`]="{ item }">
                  <div v-if="item.sta_batal != '1'">
                    <v-btn
                      v-if="item.sta_validasi != '1'"
                      color="error"
                      outlined
                      small
                      rounded
                      class="mr-2"
                      @click="showBatal(item.no_sj, item.no_bukti, item.nama)"
                      ><v-icon small class="mr-2"
                        >mdi-close-circle-outline</v-icon
                      >
                      BATAL</v-btn
                    >
                  </div>
                  <div v-else>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex align-center"
                        >
                          <v-icon color="error" small>mdi-close-circle</v-icon>
                          {{ formatDate(item.tgl_batal) }}
                        </div>
                      </template>
                      <!-- Tooltip content -->
                      <span>
                        <div>Ket : {{ item.ket_batal }}</div>
                        <div>User : {{ item.user_batal }}</div>
                        <div>Waktu : {{ formatDateTime(item.tgl_batal) }}</div>
                      </span>
                    </v-tooltip>
                  </div>
                </template>
                <!-- END BATAL PENGIRIMAN -->
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- DIALOG VALIDASI -->
    <v-dialog
      v-model="pengiriman.dialog"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card class="pb-4">
        <v-card-title background-color="success">
          VALIDASI PENGIRIMAN : {{ saved.noSj }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-text-field
            label="No. SJ"
            :value="saved.noSj"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-text-field
            label="No. Nota"
            :value="saved.noTransaksi"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-text-field
            label="Customer"
            :value="saved.nama"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-menu
            v-model="pengiriman.pickerTanggal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Update Tanggal Nota"
                :value="formatDate(pengiriman.tanggal)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="pengiriman.tanggal"
              @change="loadData()"
              @input="pengiriman.pickerTanggal = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
            label="Keterangan Validasi"
            dense
            outlined
            v-model="pengiriman.keterangan"
            ref="inputKetValidasi"
            background-color="light-blue lighten-5"
          ></v-text-field>
          <div class="d-flex justify-end">
            <v-btn
              color="success"
              :loading="pengiriman.loading"
              @click="validasiKirim()"
              ><v-icon class="mr-2">mdi-floppy</v-icon>VALIDASI</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- END DIALOG VALIDASI -->

    <!-- DIALOG BATAL -->
    <v-dialog
      v-model="batal.dialog"
      max-width="800px"
      transition="dialog-transition"
    >
      <v-card class="pb-4">
        <v-card-title background-color="success">
          PEMBATALAN PENGIRIMAN/PENJUALAN : {{ saved.noSj }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <v-text-field
            label="No. SJ"
            :value="saved.noSj"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-text-field
            label="No. Nota"
            :value="saved.noTransaksi"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-text-field
            label="Customer"
            :value="saved.nama"
            dense
            outlined
            readonly
            background-color="blue-grey lighten-5"
          ></v-text-field>
          <v-text-field
            label="Keterangan Pembatalan"
            dense
            outlined
            v-model="batal.keterangan"
            ref="inputKetBatal"
            background-color="light-blue lighten-5"
          ></v-text-field>
          <div class="d-flex justify-end">
            <v-btn color="error" :loading="batal.loading" @click="batalKirim()"
              ><v-icon class="mr-2">mdi-floppy</v-icon>BATAL</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- END DIALOG BATAL -->

    <!-- CETAK SURAT JALAN -->
    <v-dialog
      v-model="suratjalan.dialog"
      scrollable
      fullscreen
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card elevation="0">
        <v-col class="pa-0">
          <v-card-title class="py-1 d-flex justify-space-around">
            <div class="text-h4">
              <strong>{{ $store.state.auth.company.name }}</strong>
            </div>
            <v-spacer></v-spacer>
            <v-chip label outlined color="black" large
              ><div class="text-h5">SURAT JALAN</div></v-chip
            >
          </v-card-title>
          <hr />
          <v-card-text>
            <!-- HEADER -->
            <table>
              <tr>
                <td>No. Nota</td>
                <td>: {{ suratjalan.items.no_sj }}</td>
              </tr>
              <tr>
                <td>Customer</td>
                <td>: {{ suratjalan.items.nama }}</td>
              </tr>
              <tr>
                <td>Tanggal</td>
                <td>: {{ formatDate(suratjalan.items.tgl_kirim) }}</td>
              </tr>
            </table>

            <!-- <v-divider class="my-2"></v-divider> -->
            <!-- DETAIL -->
            <table width="100%" class="tabledetail mt-4">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Barang</th>
                  <th>Unit</th>
                  <th>Jumlah</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in suratjalan.items.detail"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.nama_bahan }}</td>

                  <td align="right">{{ item.unit }}</td>
                  <td align="right">{{ formatNumber(item.jumlah) }}</td>
                </tr>
                <tr>
                  <td align="right" colspan="3">Total Qty</td>
                  <td align="right">
                    <strong>
                      {{ formatNumber(suratjalan.items.tot_qty) }}</strong
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- TOTAL -->

            <div class="d-flex justify-end">
              <v-col cols="3">
                <div class="d-flex justify-center">Admin</div>
                <v-spacer class="my-12"></v-spacer>
                <div class="d-flex justify-center">
                  <u
                    ><strong> {{ invoice.items[0].user }}</strong></u
                  >
                </div>
              </v-col>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-card-text>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- CETAK SURAT JALAN -->

    <!-- CETAK INVOICE -->
    <v-dialog
      v-model="invoice.dialog"
      scrollable
      fullscreen
      :overlay="false"
      transition="dialog-transition"
    >
      <v-card elevation="0">
        <v-col class="pa-0">
          <v-card-title class="py-1 d-flex justify-space-around">
            <!-- <v-img
              :src="setLogo()"
              max-width="150px"
              height="100px"
            >g
            </v-img> -->
            <div class="text-h4">
              <strong>{{ $store.state.auth.company.name }}</strong>
            </div>
            <strong></strong>
            <v-spacer></v-spacer>
            <div class="text-h6">
              <strong> {{ invoice.items[0].kode_outlet }}</strong>
            </div>
            <!-- <v-chip label outlined color="black" large>
              <div class="text-h5">NOTA PENJUALAN</div></v-chip> -->
          </v-card-title>
          <hr />
          <v-card-text class="pt-1">
            <!-- HEADER -->
            <div class="d-flex justify-space-around">
              <table>
                <tr>
                  <td>No. Nota</td>
                  <td>: {{ invoice.items[0].no_bukti }}</td>
                </tr>
                <tr>
                  <td>Pembayaran</td>
                  <td>: {{ invoice.items[0].pembayaran.toUpperCase() }}</td>
                </tr>
                <tr>
                  <td>Tanggal</td>
                  <td>: {{ formatDate2(invoice.items[0].tgl_update) }}</td>
                </tr>
                <tr
                  v-if="
                    invoice.items[0].pola_visit == '' ||
                    invoice.items[0].pola_visit == null
                  "
                >
                  <td>Kunjungan</td>
                  <td>: -</td>
                </tr>
                <tr v-else>
                  <td>Kunjungan</td>
                  <td>
                    : {{ invoice.items[0].pola_visit }},
                    {{ invoice.items[0].hari_visit }}
                  </td>
                </tr>
              </table>
              <v-spacer></v-spacer>
              <table>
                <tr>
                  <td>Kepada Yth,</td>
                </tr>
                <tr>
                  <td class="d-flex justify-left align-center">
                    {{ invoice.items[0].nama }}
                    <div class="text-caption ml-2">
                      ({{ invoice.items[0].kd_customer }})
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="text-caption">{{ invoice.items[0].telepon }}</td>
                </tr>
                <tr>
                  <td class="text-caption">
                    {{ invoice.items[0].alamat }},
                    {{ invoice.items[0].kecamatan }},
                    {{ invoice.items[0].kabupaten }}
                  </td>
                </tr>
              </table>
            </div>

            <!-- <v-divider class="my-2"></v-divider> -->
            <!-- DETAIL -->
            <table width="100%" class="tabledetail mt-4">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Nama Barang</th>
                  <th>Satuan</th>
                  <th>Jumlah</th>
                  <th>Harga Satuan</th>
                  <th>Subtotal</th>
                  <th>Diskon</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in invoice.items" :key="item.urut">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.nama_bahan }}</td>
                  <td>{{ item.unit }}</td>
                  <td align="right">{{ formatNumber(item.jumlah) }}</td>
                  <td align="right">{{ formatNumber(item.rp_jual) }}</td>
                  <td align="right">
                    {{ formatNumber(item.jumlah * item.rp_jual) }}
                  </td>
                  <td align="right">
                    <!-- {{ formatNumber(item.jumlah * item.diskon_item) }} -->
                    <!-- @{{ formatNumber(item.diskon_item) }} = -->
                    {{ formatNumber(item.jumlah * item.diskon_item) }}
                  </td>
                </tr>
                <tr>
                  <td align="right" colspan="6">Total Rp.</td>
                  <td align="right">
                    <strong>
                      {{ formatNumber(invoice.items[0].total_kotor) }}</strong
                    >
                  </td>
                </tr>
                <tr>
                  <td align="right" colspan="6">Total Diskon Rp.</td>
                  <td align="right">
                    <strong>{{ formatNumber(invoice.items[0].diskon) }}</strong>
                  </td>
                </tr>
                <tr>
                  <td align="right" colspan="6">Total Pembayaran Rp.</td>
                  <td align="right">
                    <strong>{{
                      formatNumber(invoice.items[0].total_net)
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- TOTAL -->
            <div v-if="invoice.items[0].pembayaran.toUpperCase() == 'KREDIT'">
              <strong
                >Tanggal Jatuh Tempo :
                {{ formatDate2(invoice.items[0].tgl_jatuhtempo) }}</strong
              >
            </div>
            <div class="mt-2">
              <strong>
                <div>Pembayaran Melalui :</div>
                <div>Nurulliana Maharsi BCA 0301219015</div>
              </strong>
            </div>

            <div class="d-flex justify-space-between">
              <v-col cols="3">
                <div class="d-flex justify-center">Penerima</div>
                <v-spacer class="my-12"></v-spacer>
                <div class="d-flex justify-center">
                  <strong></strong>
                </div>
                <hr />

                <div class="d-flex justify-center">Nama, TTD/Cap</div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex justify-center">Hormat Kami</div>
                <v-spacer class="my-7"></v-spacer>

                <div class="d-flex justify-center">
                  <strong> {{ invoice.items[0].user.toUpperCase() }}</strong>
                </div>
                <hr />
                <div class="d-flex justify-center">Petugas Pemasaran</div>
              </v-col>
            </div>
            <v-divider class="my-2"></v-divider>
          </v-card-text>
        </v-col>
      </v-card>
    </v-dialog>
    <!-- END CETAK INVOICE -->

    <!-- CETAK INVOICE -->
    <v-dialog
      v-model="invoiceDev.dialog1"
      scrollable
      fullscreen
      :overlay="false"
      transition="dialog-transition"
    >
      <v-card>
        <div id="content">
          <table
            style="
              width: 100%;
              border-collapse: collapse;
              margin-left: auto;
              margin-right: auto;
              border-style: none;
            "
          >
            <tbody>
              <tr>
                <td style="width: 50%; border-style: none" align="left">
                  <h2>
                    <strong>{{ $store.state.auth.company.name }}</strong>
                  </h2>
                </td>
                <td style="width: 50%" align="right">
                  <strong>{{ invoice.items[0].kode_outlet }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <table
            style="
              border-collapse: collapse;
              width: 100%;
              border-style: none;
              margin-top: 5px;
            "
          >
            <tbody>
              <tr>
                <td style="width: 50%">
                  No. Nota : {{ invoice.items[0].no_bukti }}<br />
                  Pembayaran : {{ invoice.items[0].pembayaran.toUpperCase()
                  }}<br />
                  Tanggal : {{ formatDate(invoice.items[0].tgl_update) }}<br />
                  Kunjungan : {{ invoice.items[0].pola_visit }},
                  {{ invoice.items[0].hari_visit }}
                </td>
                <td style="width: 50%">
                  Pemesan: {{ invoice.items[0].nama }} ({{
                    invoice.items[0].kd_customer
                  }}) <br />
                  {{ invoice.items[0].telepon }} <br />
                  {{ invoice.items[0].alamat }},
                  {{ invoice.items[0].kecamatan }},
                  {{ invoice.items[0].kabupaten }}
                </td>
              </tr>
            </tbody>
          </table>

          <!-- GRID -->
          <table
            style="border-collapse: collapse; margin-top: 10px"
            border="1"
            class="grid"
          >
            <thead>
              <tr>
                <th style="width: 2%" align="center">No.</th>
                <th style="width: 30%">Nama Barang</th>
                <th style="width: 5%">Sat.</th>
                <th style="width: 7%">Jum.</th>
                <th style="width: 10%">Harga</th>
                <th style="width: 12%">Subtotal</th>
                <th style="width: 7%">Diskon</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in invoice.items" :key="item.urut">
                <td align="center">{{ index + 1 }}</td>
                <td>{{ item.nama_bahan }}</td>
                <td>{{ item.unit }}</td>
                <td align="right">{{ formatNumber(item.jumlah) }}</td>
                <td align="right">{{ formatNumber(item.rp_jual) }}</td>
                <td align="right">
                  {{ formatNumber(item.jumlah * item.rp_jual) }}
                </td>
                <td align="right">
                  <!-- {{ formatNumber(item.jumlah * item.diskon_item) }} -->
                  <!-- @{{ formatNumber(item.diskon_item) }} = -->
                  {{ formatNumber(item.jumlah * item.diskon_item) }}
                </td>
              </tr>
              <tr>
                <td align="right" colspan="6">Total Rp.</td>
                <td align="right">
                  <strong>
                    {{ formatNumber(invoice.items[0].total_kotor) }}</strong
                  >
                </td>
              </tr>
              <tr>
                <td align="right" colspan="6">Total Diskon Rp.</td>
                <td align="right">
                  <strong>{{ formatNumber(invoice.items[0].diskon) }}</strong>
                </td>
              </tr>
              <tr>
                <td align="right" colspan="6">Total Pembayaran Rp.</td>
                <td align="right">
                  <strong>{{
                    formatNumber(invoice.items[0].total_net)
                  }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style="
              width: 100%;
              border-collapse: collapse;
              margin-left: auto;
              margin-right: auto;
              border-style: none;
              margin-top: 10px;
            "
          >
            <tbody>
              <tr>
                <td style="width: 50%; border-style: none">
                  <strong
                    >Tanggal Jatuh Tempo :
                    {{ formatDate2(invoice.items[0].tgl_jatuhtempo) }}
                  </strong>
                  <br />
                  <strong
                    >Pembayaran Melalui : <br />
                    Nurulliana Maharsi BCA 0301219015
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            style="
              width: 100%;
              border-collapse: collapse;
              margin-left: auto;
              margin-right: auto;
              border-style: none;
              margin-top: 10px;
            "
          >
            <tbody>
              <tr>
                <td align="center" style="width: 50%; border-style: none">
                  Penerima
                </td>
                <td align="center" style="width: 50%; border-style: none">
                  Hormat Kami
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="width: 50%; border-style: none"></td>
                <td style="width: 50%; border-style: none"></td>
              </tr>
              <tr>
                <td style="width: 50%; border-style: none"></td>
                <td align="center" style="width: 50%; border-style: none">
                  {{ invoice.items[0].user.toUpperCase() }}
                </td>
              </tr>
              <tr>
                <td align="center" style="width: 50%; border-style: none">
                  Nama, TTD/Cap
                </td>
                <td align="center" style="width: 50%; border-style: none">
                  Petugas Pemasaran
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-dialog>
    <!-- END CETAK INVOICE DEV-->

    <!-- CETAK INVOICE -->
    <v-dialog
      v-model="invoiceDev.dialog2"
      scrollable
      fullscreen
      :overlay="false"
      transition="dialog-transition"
    >
      <v-card>
        <v-btn
          color="error"
          @click="(invoiceDev.dialog1 = false), (invoiceDev.dialog2 = false)"
          ><v-icon>mdi-close-thick</v-icon></v-btn
        >
        <iframe
          v-if="pdfDataUrl"
          :src="pdfDataUrl"
          width="100%"
          height="100%"
        ></iframe>
      </v-card>
    </v-dialog>
    <!-- END CETAK INVOICE DEV-->

    <!-- <div>
      <button @click="generatePDF">Generate PDF</button>
      <iframe
        v-if="pdfDataUrl"
        :src="pdfDataUrl"
        width="100%"
        height="500px"
      ></iframe>
    </div> -->
  </v-container>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      pdfDataUrl: null,
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. SJ", value: "no_sj", divider: true },
          { text: "NO. NOTA", value: "no_bukti", divider: true },
          { text: "CUSTOMER", value: "nama", divider: true },
          { text: "JUMLAH", value: "jumlah", divider: true, align: "right" },
          { text: "OPSI", value: "opsi", divider: true },
          { text: "VALIDASI", value: "validasi", divider: true },
          { text: "OPSI BATAL", value: "opsi_batal", divider: true },
        ],
        items: [],
        loading: false,
        search: "",
      },
      suratjalan: {
        dialog: false,
        items: [
          {
            no_bukti: "",
            pembayaran: "",
            user: "",
            tgl_update: "",
            total: "",
            diskon: "",
            bayar: "",
            kembali: "",
          },
        ],
      },
      invoice: {
        dialog: false,
        items: [
          {
            no_bukti: "0276/TR/06/2024",
            tanggal: "2024-06-06 03:20:08",
            tgl_jatuhtempo: "2024-06-20 03:20:08",
            user: "ROBBY",
            kd_customer: "101-01-00-1583",
            nama: "TIGA DEWI",
            kode_outlet: "0",
            hari_visit: "SELASA",
            pola_visit: "GANJIL",
            telepon: "082322272267",
            alamat: "Jl Saputan",
            kecamatan: "Semarang Barat",
            kabupaten: "Kota Semarang",
            tgl_update: "2024-06-06 03:20:08",
            pembayaran: "kredit",
            total_kotor: 57300,
            total_net: 57300,
            diskon: 0,
            bayar: 57300,
            kembali: 0,
            urut: 1,
            kode_bahan: "02-0049",
            nama_bahan: "Golden Panda Makaroni Ayam Geprek (isi 40)",
            unit: "Pcs",
            jumlah: 10,
            rp_jual: 1488,
            diskon_item: 0,
            total_item_net: 14880,
          },
          {
            no_bukti: "0276/TR/06/2024",
            tanggal: "2024-06-06 03:20:08",
            tgl_jatuhtempo: "2024-06-20 03:20:08",
            user: "ROBBY",
            kd_customer: "101-01-00-1583",
            nama: "TIGA DEWI",
            kode_outlet: "0",
            hari_visit: "SELASA",
            pola_visit: "GANJIL",
            telepon: "082322272267",
            alamat: "Jl Saputan",
            kecamatan: "Semarang Barat",
            kabupaten: "Kota Semarang",
            tgl_update: "2024-06-06 03:20:08",
            pembayaran: "kredit",
            total_kotor: 57300,
            total_net: 57300,
            diskon: 0,
            bayar: 57300,
            kembali: 0,
            urut: 2,
            kode_bahan: "02-0050",
            nama_bahan: "Golden Panda Makaroni Seblak (isi 40)",
            unit: "Pcs",
            jumlah: 10,
            rp_jual: 1488,
            diskon_item: 0,
            total_item_net: 14880,
          },
          {
            no_bukti: "0276/TR/06/2024",
            tanggal: "2024-06-06 03:20:08",
            tgl_jatuhtempo: "2024-06-20 03:20:08",
            user: "ROBBY",
            kd_customer: "101-01-00-1583",
            nama: "TIGA DEWI",
            kode_outlet: "0",
            hari_visit: "SELASA",
            pola_visit: "GANJIL",
            telepon: "082322272267",
            alamat: "Jl Saputan",
            kecamatan: "Semarang Barat",
            kabupaten: "Kota Semarang",
            tgl_update: "2024-06-06 03:20:08",
            pembayaran: "kredit",
            total_kotor: 57300,
            total_net: 57300,
            diskon: 0,
            bayar: 57300,
            kembali: 0,
            urut: 3,
            kode_bahan: "05-0034",
            nama_bahan: "Panda Pop Kron Chocolate",
            unit: "PCS",
            jumlah: 20,
            rp_jual: 1377,
            diskon_item: 0,
            total_item_net: 27540,
          },
          // {
          //   no_bukti: "",
          //   pembayaran: "",
          //   user: "",
          //   tgl_update: "",
          //   total_kotor: "",
          //   total_net: "",
          //   diskon: "",
          //   bayar: "",
          //   kembali: "",
          // },
        ],
      },
      saved: {
        status: false,
        noSj: "",
        noTransaksi: "",
        nama: "",
      },
      pengiriman: {
        dialog: false,
        keterangan: "",
        loading: false,
        user: this.$store.state.auth.user.name,
        pickerTanggal: false,
        tanggal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      batal: {
        dialog: false,
        keterangan: "",
        loading: false,
        user: this.$store.state.auth.user.name,
      },
      invoiceDev: {
        dialog1: false,
        dialog2: false,
        items: [],
      },
    };
  },
  mounted() {
    this.loadData(0);
  },
  methods: {
    async printInvoiceDev(noBukti) {
      this.saved.noTransaksi = noBukti;
      this.invoiceDev.dialog1 = true;
      await axios
        .post("penjualan/transaksi/print", {
          noBukti: this.saved.noTransaksi,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({
              type: "error",
              text: res.data.message,
            });
            return;
          }
          this.invoice.items = res.data.data;
          this.generatePDF();
          this.invoiceDev.dialog2 = true;
          this.updatePrintInvoice();
          setTimeout(() => {
            this.invoiceDev.dialog1 = false;
          }, 500);
        });
    },
    async generatePDF() {
      this.pdfDataUrl = "";
      const element = document.getElementById("content");
      const opt = {
        margin: 1,
        filename: "nota.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
      };

      // Create PDF and convert it to a Blob
      const pdfBlob = await html2pdf().from(element).set(opt).outputPdf("blob");

      // Convert Blob to Data URL
      const pdfDataUrl = await this.blobToDataURL(pdfBlob);

      // Set the data URL to display the PDF in an iframe
      this.pdfDataUrl = pdfDataUrl;
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async loadData() {
      this.data.loading = true;
      await axios
        .post("penjualan/pengiriman", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
        });
      this.data.loading = false;
    },
    printSj(noSj) {
      this.saved.noSj = noSj;
      this.suratjalan.dialog = true;
      this.loadPrintSj();
      this.updatePrintSj();
    },
    async updatePrintSj() {
      axios
        .post("penjualan/pengiriman/updatestaprint/sj", {
          no_sj: this.saved.noSj,
        })
        .then(() => {
          this.loadData();
        });
    },
    async loadPrintSj() {
      console.log("LOAD PRINT SJ");
      await axios
        .post("penjualan/pengiriman/bysj", {
          noSj: this.saved.noSj,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({
              type: "error",
              text: res.data.message,
            });
            return;
          }
          this.suratjalan.items = res.data.data;
          setTimeout(() => {
            window.print();
          }, 1000);
          setTimeout(() => {
            this.suratjalan.dialog = false;
          }, 1000);
        });
    },

    // INVOICE

    printInvoice(noBukti) {
      this.saved.noTransaksi = noBukti;
      this.invoice.dialog = true;
      this.loadPrintInvoice();
      this.updatePrintInvoice();
    },

    async updatePrintInvoice() {
      axios
        .post("penjualan/pengiriman/updatestaprint/nota", {
          no_bukti: this.saved.noTransaksi,
        })
        .then(() => {
          this.loadData();
        });
    },
    async loadPrintInvoice() {
      await axios
        .post("penjualan/transaksi/print", {
          noBukti: this.saved.noTransaksi,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({
              type: "error",
              text: res.data.message,
            });
            return;
          }
          this.invoice.items = res.data.data;
          setTimeout(() => {
            window.print();
          }, 500);
          setTimeout(() => {
            this.invoice.dialog = false;
          }, 500);
        });
    },

    // VALIDASI PENGIRIMAN
    showValidasi(no_sj, noBukti, nama) {
      this.resetForm();
      this.pengiriman.dialog = true;
      this.saved.noSj = no_sj;
      this.saved.noTransaksi = noBukti;
      this.saved.nama = nama;
      setTimeout(() => {
        this.$refs.inputKetValidasi.focus();
      }, 300);
    },
    async validasiKirim() {
      this.pengiriman.loading = true;
      await axios
        .post("penjualan/pengiriman/updatevalidasi", {
          no_sj: this.saved.noSj,
          noTransaksi: this.saved.noTransaksi,
          ket: this.pengiriman.keterangan,
          user: this.pengiriman.user,
          tanggal: this.pengiriman.tanggal,
        })
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.message, "error");
            return;
          }

          swal("Success", res.data.message, "success");
          this.loadData();
          this.resetForm();
        })
        .catch((err) => console.log(err));
      this.pengiriman.loading = false;
    },

    // PEMBATALAN PENJUALAN
    showBatal(no_sj, noBukti, nama) {
      this.resetForm();
      this.batal.dialog = true;
      this.saved.noSj = no_sj;
      this.saved.noTransaksi = noBukti;
      this.saved.nama = nama;
      setTimeout(() => {
        this.$refs.inputKetBatal.focus();
      }, 300);
    },
    async batalKirim() {
      this.batal.loading = true;
      await axios
        .post("penjualan/pengiriman/updatebatal", {
          no_sj: this.saved.noSj,
          noTransaksi: this.saved.noTransaksi,
          ket: this.batal.keterangan,
          user: this.batal.user,
        })
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.message, "error");
            return;
          }

          swal("Success", res.data.message, "success");
          this.loadData();
          this.resetForm();
        })
        .catch((err) => console.log(err));
      this.batal.loading = false;
    },

    resetForm() {
      this.pengiriman.dialog = false;
      this.pengiriman.keterangan = "";
      this.batal.dialog = false;
      this.batal.keterangan = "";
      this.saved.noSj = "";
      this.saved.noTransaksi = "";
      this.saved.nama = "";
    },

    setLogo() {
      return "https://api.sinarpanganfood.com/public/SPF.png";
    },
  },
};
</script>

<style scoped>
.tabledetail {
  border-collapse: collapse;
  margin: 12px 0;
  font-size: 1.1em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
}

.tabledetail thead tr,
th {
  background-color: #f1f1f1;
  color: #1a1919;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #303030;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #8b8b8b;
}
.tabledetail th,
.tabledetail td {
  padding: 3px 12px;
}
.tabledetail tbody tr td {
  border-bottom: 1px solid #303030;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}
/* .tabledetail tbody tr:nth-of-type(even) {
  background: #f3f3f3;
} */
.tabledetail tbody tr:last-of-type {
  border-bottom: 2px solid #525252;
}

.grid th,
.grid td {
  padding: 0px 5px;
}

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
#content {
  width: calc(21cm - 2cm); /* A4 width - left and right margins */
  min-height: calc(29.7cm - 2cm); /* A4 height - top and bottom margins */
  padding: 0;
  margin: auto;
  background: white;
  font-family: "Roboto", sans-serif;
}
</style>